/* eslint-disable react-hooks/exhaustive-deps */
import {
	App,
	Button,
	Card,
	Col,
	Descriptions,
	Dropdown,
	Modal,
	Popconfirm,
	Radio,
	Row,
	Space,
	Spin,
	Table,
	Tag,
	Tooltip,
	Typography,
} from "antd";
import {
	CheckCircleFilled,
	CloseCircleFilled,
	DownCircleFilled,
	DownOutlined,
	PlusCircleFilled,
	RightCircleFilled,
	UpCircleFilled,
} from "@ant-design/icons";
import React, { useEffect } from "react";
import {
	requestGetEventList,
	requestGetFeeList,
	requestGetInvoiceList,
	requestUnassignTeacherFromClassroom,
	requestUnassignTeacherFromLesson,
	requestUnenrollStudentsFromClassroom,
	showClassroom,
} from "../../../../services/admin.service";
import TeacherAssignFormModal from "../../../../components/TeacherAssignFormModal";
import StudentEnrollFormModal from "../../../../components/StudentEnrollFormModal";
import { HiClipboardDocumentCheck, HiClipboardDocumentList } from "react-icons/hi2";
import dayjs from "dayjs";
import ClassroomDailyReportModal from "./ClassroomDailyReportModal";
import ClassroomPromotionFormModal from "./ClassroomPromotionFormModal";

dayjs.extend(require("dayjs/plugin/isSameOrAfter"));
dayjs.extend(require("dayjs/plugin/isSameOrBefore"));

const studentColumns = [
	{
		title: "No",
		dataIndex: "order",
		fixed: "left",
		key: "order",
		width: 36,
	},
	{
		title: "No. Induk",
		dataIndex: "number",
		key: "number",
		width: 100,
	},
	{
		title: "Nama",
		dataIndex: "name",
		key: "name",
	},
];

const lessonColumns = [
	{
		title: "Matpel",
		dataIndex: "name",
		key: "name",
	},
	{
		title: "Pengajar",
		dataIndex: "teachers",
		key: "teachers",
	},
];

export const ClassroomDetailModal = ({
	id,
	year,
	isVisible,
	isLoading,
	onLoading,
	onClose,
	onSuccess,
	activeYear,
}) => {
	const { notification } = App.useApp();
	const [classroom, setClassroom] = React.useState(null);
	const [isTeacherAssignVisible, setTeacherAssignVisible] = React.useState(false);
	const [isStudentEnrollVisible, setStudentEnrollVisible] = React.useState(false);
	const [isDailyReportVisible, setDailyReportVisible] = React.useState(false);
	const [columns, setColumns] = React.useState(studentColumns);

	const [students, setStudents] = React.useState([]);
	const [lessons, setLessons] = React.useState([]);
	const [mappedLessons, setMappedLessons] = React.useState([]);
	const [mappedStudents, setMappedStudents] = React.useState([]);

	const [lesson, setLesson] = React.useState(null);
	const [assignType, setAssignType] = React.useState("");
	const [mode, setMode] = React.useState("student");

	const [invoices, setInvoices] = React.useState([]);

	const [selectedStudentIds, setSelectedStudentIds] = React.useState([]);
	const [isPromotionFormVisible, setPromotionFormVisible] = React.useState(false);

	useEffect(() => {
		if (id) {
			fetchClassroom();
		} else {
			setClassroom(null);
			setAssignType("");
		}
		setMode("");
	}, [id]);

	useEffect(() => {
		if (classroom) {
			updateMappedItems();
		}
	}, [classroom]);

	useEffect(() => {
		setColumns(studentColumns);
		if (mode === "attendance") {
			fetchClassroomAttendances();
		} else if (mode === "invoice") {
			fetchInvoices();
		} else {
			setColumns(studentColumns);
		}
	}, [mode]);

	useEffect(() => {
		if (invoices.length > 0) {
			fetchFees();
		}
	}, [invoices]);

	const updateMappedItems = (invoices = null, attendances = null) => {
		const lessons = classroom.lessons;
		const students = classroom.students;

		setLessons(lessons);
		setStudents(students);

		const mappedLessons = lessons.map((lesson) => {
			const teachers = lesson.teachers;

			return {
				key: lesson._id,
				name: lesson.subject.name,
				teachers: (
					<>
						<div>
							{teachers.map((teacher, index) => {
								return (
									<Space size={1} direction="horizontal" key={index}>
										<Typography.Text>
											{teacher.name}
											<Tag
												color="blue"
												style={{
													marginLeft: 4,
													marginRight: 0,
												}}>
												{teacher.type === "MAIN"
													? "Utama"
													: teacher.type === "ASSISTANT"
													? "Pendamping"
													: "Pengganti"}
											</Tag>
										</Typography.Text>
										<Popconfirm
											placement="topRight"
											title={`Anda yakin akan membebastugaskan ${teacher.name}?`}
											onConfirm={(e) =>
												handleUnassignTeacherButton(lesson._id, teacher._id, lesson)
											}
											okText="Yes"
											cancelText="No">
											<Tooltip title="Bebastugaskan Pengajar">
												<Button
													type="link"
													shape="default"
													size="small"
													icon={<CloseCircleFilled />}
													danger
												/>
											</Tooltip>
										</Popconfirm>
									</Space>
								);
							})}

							<Tooltip title="Tugaskan Pengajar">
								<Button
									type="dashed"
									shape="circle"
									size="small"
									onClick={() => handleAssignTeacherButton(lesson, "")}>
									<PlusCircleFilled />
								</Button>
							</Tooltip>
						</div>
					</>
				),
			};
		});

		setMappedLessons(mappedLessons);

		const mappedStudents = students
			.sort((a, b) => ("" + a.name).localeCompare(b.name))
			.map((student, index) => {
				return {
					key: student._id,
					order: index + 1,
					number: student.number,
					name: (
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
								gap: 8,
							}}>
							<Typography.Text>{student.name}</Typography.Text>

							<Tooltip title="Keluarkan dari kelas">
								<Popconfirm
									placement="topRight"
									title={`Anda yakin akan mengeluarkan ${student.name}?`}
									onConfirm={(e) => handleUnenrollStudent(student._id)}
									okText="Ya"
									cancelText="Tdk">
									<Button
										type="link"
										shape="default"
										size="small"
										icon={<CloseCircleFilled />}
										danger
									/>
								</Popconfirm>
							</Tooltip>
						</div>
					),
				};
			});

		setMappedStudents(mappedStudents);
	};

	const fetchClassroom = () => {
		onLoading(true);
		showClassroom(id)
			.then((response) => {
				const classroom = response.data.data;

				setClassroom(classroom);
			})
			.catch((error) => {})
			.finally(() => {
				onLoading(false);
			});
	};

	const fetchFees = () => {
		onLoading(true);

		requestGetFeeList({
			order: "type:asc",
			limit: 1000,
		})
			.then((response) => {
				const fees = response.data.data;

				const cols = [...studentColumns];
				fees.forEach((fee) => {
					if (fee.type === "MONTHLY") {
						// generate from beginning of the year until end of the year
						const start = dayjs(year.startedAt);
						const end = dayjs(year.endedAt);

						const months = [];
						let current = start;
						while (current.isBefore(end)) {
							months.push(current.format("MMMM"));
							current = current.add(1, "month");
						}

						months.forEach((month) => {
							cols.push({
								title: fee.name + " " + month.substring(0, 3),
								dataIndex: fee._id,
								key: fee._id,
								align: "center",
								width: 100,
								render: (text, record) => {
									const invoice = invoices.find(
										(invoice) =>
											dayjs(invoice.issuedAt).format("MMMM") === month &&
											invoice.student._id === record.key &&
											invoice.items.map((item) => item.fee._id).includes(fee._id)
									);

									if (invoice) {
										if (invoice.status === "PAID") {
											return (
												<Tag color="green" style={{ fontWeight: "normal" }}>
													Lunas
												</Tag>
											);
										} else {
											return (
												<Tag color="red" style={{ fontWeight: "normal" }}>
													Belum Lunas
												</Tag>
											);
										}
									} else {
										return (
											<Tag color="default" style={{ fontWeight: "normal" }}>
												Belum ada
											</Tag>
										);
									}
								},
							});
						});
					} else if (fee.type === "SEMESTRIAL") {
						const semesters = year.semesters;
						semesters.forEach((semester) => {
							cols.push({
								title: fee.name + " " + semester,
								dataIndex: fee._id,
								key: fee._id,
								align: "center",
								width: 100,
								render: (text, record) => {
									const invoice = invoices.find(
										(invoice) =>
											dayjs(invoice.issuedAt).isSameOrAfter(semester.startedAt) &&
											dayjs(invoice.issuedAt).isSameOrBefore(semester.endedAt) &&
											invoice.student._id === record.key &&
											invoice.items.map((item) => item.fee._id).includes(fee._id)
									);

									if (invoice) {
										if (invoice.status === "PAID") {
											return (
												<Tag color="green" style={{ fontWeight: "normal" }}>
													Lunas
												</Tag>
											);
										} else {
											return (
												<Tag color="red" style={{ fontWeight: "normal" }}>
													Belum Lunas
												</Tag>
											);
										}
									} else {
										return (
											<Tag color="default" style={{ fontWeight: "normal" }}>
												Belum ada
											</Tag>
										);
									}
								},
							});
						});
					} else if (fee.type === "PERIODICAL") {
						cols.push({
							title: fee.name + " " + year.name,
							dataIndex: fee._id,
							key: fee._id,
							align: "center",
							width: 100,
							render: (text, record) => {
								const invoice = invoices.find(
									(invoice) =>
										dayjs(invoice.issuedAt).isSameOrAfter(year.startedAt) &&
										dayjs(invoice.issuedAt).isSameOrBefore(year.endedAt) &&
										invoice.student._id === record.key &&
										invoice.items.map((item) => item.fee._id).includes(fee._id)
								);

								if (invoice) {
									if (invoice.status === "PAID") {
										return (
											<Tag color="green" style={{ fontWeight: "normal" }}>
												Lunas
											</Tag>
										);
									} else {
										return (
											<Tag color="red" style={{ fontWeight: "normal" }}>
												Belum Lunas
											</Tag>
										);
									}
								} else {
									return (
										<Tag color="default" style={{ fontWeight: "normal" }}>
											Belum ada
										</Tag>
									);
								}
							},
						});
					} else if (fee.type === "ONCE") {
						cols.push({
							title: fee.name,
							dataIndex: fee._id,
							key: fee._id,
							align: "center",
							width: 100,
							render: (text, record) => {
								const invoice = invoices.find(
									(invoice) =>
										invoice.student._id === record.key &&
										invoice.items.map((item) => item.fee._id).includes(fee._id)
								);

								if (invoice) {
									if (invoice.status === "PAID") {
										return (
											<Tag color="green" style={{ fontWeight: "normal" }}>
												Lunas
											</Tag>
										);
									} else {
										return (
											<Tag color="red" style={{ fontWeight: "normal" }}>
												Belum Lunas
											</Tag>
										);
									}
								} else {
									return (
										<Tag color="default" style={{ fontWeight: "normal" }}>
											Belum ada
										</Tag>
									);
								}
							},
						});
					}
				});

				setColumns(cols);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification.error({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification.error({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				onLoading(false);
			});
	};

	const fetchInvoices = () => {
		onLoading(true);

		requestGetInvoiceList({
			limit: 1000,
			filter: `year:${year._id},student:${classroom.students
				.map((student) => student._id)
				.join("|")}`,
		})
			.then((response) => {
				const invoices = response.data.data;
				setInvoices(invoices);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification.error({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification.error({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				onLoading(false);
			});
	};

	const fetchClassroomAttendances = () => {
		onLoading(true);

		requestGetEventList({
			order: "startedAt:asc",
			limit: 1000,
			filter: `type:CLASSROOM,reference:${classroom._id},start:${year.startedAt},end:${year.endedAt}`,
		})
			.then((response) => {
				const events = response.data.data;
				const cols = [...studentColumns];
				const semesters = year.semesters;

				for (const semester of semesters) {
					const semesterCols = [];
					const monthInSemester = dayjs(semester.endedAt).diff(semester.startedAt, "month") + 1;
					const semesterMonths = [];

					for (let i = 0; i < monthInSemester; i++) {
						semesterMonths.push(dayjs(semester.startedAt).add(i, "month").format("MMMM YYYY"));
					}

					for (let month of semesterMonths) {
						const monthCols = [];
						const monthEvents = events.filter(
							(event) => dayjs(event.startedAt).format("MMMM YYYY") === month
						);

						// PUSH TO MONTHCOLS
						for (let event of monthEvents) {
							monthCols.push({
								title: dayjs(event.startedAt).format("D"),
								dataIndex: event._id,
								key: event._id,
								align: "center",
								width: 100,
								render: (text, record) => {
									const attendance = event.report.attendees.find(
										(attendee) => attendee.referenceId === record.key
									);

									if (attendance) {
										if (attendance.status === "PRESENT") {
											return (
												<Tag color="green" style={{ fontWeight: "normal" }}>
													Hadir
												</Tag>
											);
										} else if (attendance.status === "ABSENT") {
											return (
												<Tag color="red" style={{ fontWeight: "normal" }}>
													Alpa
												</Tag>
											);
										} else if (attendance.status === "EXCUSED") {
											return (
												<Tag color="blue" style={{ fontWeight: "normal" }}>
													Izin
												</Tag>
											);
										} else if (attendance.status === "LATE") {
											return (
												<Tag color="orange" style={{ fontWeight: "normal" }}>
													Telat
												</Tag>
											);
										} else {
											return (
												<Tag color="default" style={{ fontWeight: "normal" }}>
													-
												</Tag>
											);
										}
									} else {
										return (
											<Tag color="red" style={{ fontWeight: "normal" }}>
												Alpa
											</Tag>
										);
									}
								},
							});
						}
						// PUSH TO MONTHCOLS

						if (monthCols.length > 0) {
							// PUSH TO SEMESTERCOLS
							semesterCols.push({
								title: month,
								dataIndex: month,
								key: month,
								align: "center",
								children: monthCols,
							});
							// PUSH TO SEMESTERCOLS
						}
					}

					// ADD SUMMARY PER SEMESTER
					const semesterEvents = events.filter(
						(event) =>
							dayjs(event.startedAt).isSameOrAfter(semester.startedAt) &&
							dayjs(event.startedAt).isSameOrBefore(semester.endedAt)
					);

					semesterCols.push({
						title: "Keterangan",
						dataIndex: "summary_" + semester._id,
						key: "summary_" + semester._id,
						align: "center",
						width: 100,
						children: [
							{
								title: "Hadir",
								dataIndex: "present_" + semester._id,
								key: "present_" + semester._id,
								align: "center",
								width: 100,
								render: (text, record) => {
									const present = semesterEvents.filter((event) =>
										event.report.attendees.find(
											(attendee) =>
												attendee.referenceId === record.key && attendee.status === "PRESENT"
										)
									);

									return present.length;
								},
							},
							{
								title: "Alpa",
								dataIndex: "absent_" + semester._id,
								key: "absent_" + semester._id,
								align: "center",
								width: 100,
								render: (text, record) => {
									const absent = semesterEvents.filter((event) =>
										event.report.attendees.find(
											(attendee) =>
												attendee.referenceId === record.key && attendee.status === "ABSENT"
										)
									);

									return absent.length;
								},
							},
							{
								title: "Izin",
								dataIndex: "excused_" + semester._id,
								key: "excused_" + semester._id,
								align: "center",
								width: 100,
								render: (text, record) => {
									const excused = semesterEvents.filter((event) =>
										event.report.attendees.find(
											(attendee) =>
												attendee.referenceId === record.key && attendee.status === "EXCUSED"
										)
									);

									return excused.length;
								},
							},
							{
								title: "Telat",
								dataIndex: "late_" + semester._id,
								key: "late_" + semester._id,
								align: "center",
								width: 100,
								render: (text, record) => {
									const late = semesterEvents.filter((event) =>
										event.report.attendees.find(
											(attendee) =>
												attendee.referenceId === record.key && attendee.status === "LATE"
										)
									);

									return late.length;
								},
							},
						],
					});
					// ADD SUMMARY PER SEMESTER

					if (semesterCols.length > 0) {
						cols.push({
							title: semester.name,
							dataIndex: semester._id,
							key: semester._id,
							align: "center",
							children: semesterCols,
						});
					}
				}

				setColumns(cols);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification.error({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification.error({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				onLoading(false);
			});
	};

	const handleUnenrollStudent = (studentId) => {
		onLoading(true);
		requestUnenrollStudentsFromClassroom(classroom._id, {
			studentIds: [studentId],
		})
			.then((response) => {
				fetchClassroom();
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				onLoading(false);
			});
	};

	const handleUnassignTeacherButton = (id, teacherId, lesson) => {
		onLoading(true);
		if (lesson) {
			requestUnassignTeacherFromLesson(id, { teacherIds: [teacherId] })
				.then((response) => {
					fetchClassroom();
				})
				.catch((error) => {
					if (error.response && error.response.data) {
						notification["error"]({
							message: "Kesalahan!",
							description: error.response.data.message,
						});
					} else {
						notification["error"]({
							message: "Kesalahan!",
							description: error.message,
						});
					}
				})
				.finally(() => {
					onLoading(false);
				});
		} else {
			requestUnassignTeacherFromClassroom(id, { teacherIds: [teacherId] })
				.then((response) => {
					fetchClassroom();
				})
				.catch((error) => {
					if (error.response && error.response.data) {
						notification["error"]({
							message: "Kesalahan!",
							description: error.response.data.message,
						});
					} else {
						notification["error"]({
							message: "Kesalahan!",
							description: error.message,
						});
					}
				})
				.finally(() => {
					onLoading(false);
				});
		}
	};

	const handleClose = () => {
		// form.resetFields();
		setClassroom(null);
		setMappedLessons([]);
		setMappedStudents([]);
		setLesson(null);
		onClose();
	};

	const handleEnrollStudentButton = () => {
		setStudentEnrollVisible(true);
	};

	const handleEnrollStudentClose = () => {
		setStudentEnrollVisible(false);
	};

	const handleEnrollStudentSuccess = () => {
		setStudentEnrollVisible(false);
		fetchClassroom();
	};

	const handleDailyReportClose = () => {
		setDailyReportVisible(false);
	};

	const handleDailyReportSuccess = () => {
		setDailyReportVisible(false);
		fetchClassroom();
	};

	const handleAssignTeacherButton = (lesson, type) => {
		setLesson(lesson);
		setAssignType(type);
		setTeacherAssignVisible(true);
	};

	const handleAssignTeacherClose = () => {
		setLesson(null);
		setTeacherAssignVisible(false);
	};

	const handleAssignTeacherSuccess = () => {
		setLesson(null);
		setTeacherAssignVisible(false);
		fetchClassroom();
	};

	const handleModeChange = (value) => {
		setMode(value);
	};

	const handlePromotionClose = () => {
		setPromotionFormVisible(false);
	};

	const handlePromotionSuccess = () => {
		setPromotionFormVisible(false);
	};

	const actionMenus = [
		{
			key: "PROMOTE",
			icon: <UpCircleFilled />,
			label: "Promosikan Siswa untuk Tahun Ajaran Baru",
		},
	];

	const actionMenuProps = {
		items: actionMenus,
		onClick: (e) => {
			if (e.key === "PROMOTE") {
				setPromotionFormVisible(true);
			}
		},
	};

	return (
		<>
			<TeacherAssignFormModal
				isVisible={isTeacherAssignVisible}
				isLoading={isLoading}
				onLoading={onLoading}
				onClose={handleAssignTeacherClose}
				onSuccess={handleAssignTeacherSuccess}
				classroom={assignType === "CLASSROOM" ? classroom : null}
				lesson={lesson}
			/>
			<StudentEnrollFormModal
				isVisible={isStudentEnrollVisible}
				isLoading={isLoading}
				onLoading={onLoading}
				onClose={handleEnrollStudentClose}
				onSuccess={handleEnrollStudentSuccess}
				classroom={classroom}
			/>
			<ClassroomDailyReportModal
				classroom={classroom}
				isVisible={isDailyReportVisible}
				onClose={handleDailyReportClose}
			/>
			<ClassroomPromotionFormModal
				isVisible={isPromotionFormVisible}
				isLoading={isLoading}
				onLoading={onLoading}
				onClose={handlePromotionClose}
				onSuccess={handlePromotionSuccess}
				classroom={classroom}
				studentIds={selectedStudentIds}
				activeYear={activeYear}
			/>
			{/* <ClassroomDailyReportFormModal
				isVisible={isDailyReportFormVisible}
				isLoading={isLoading}
				onLoading={onLoading}
				onClose={handleDailyReportClose}
				onSuccess={handleDailyReportSuccess}
				classroom={classroom}
				students={students}
			/> */}
			<Modal
				width={1024}
				title={"Detail Kelas"}
				open={isVisible}
				cancelText="Batal"
				onCancel={handleClose}
				footer={[
					<Button key={1} onClick={handleClose}>
						Tutup
					</Button>,
				]}>
				<Spin spinning={isLoading}>
					{classroom && (
						<Row gutter={{ xs: 8, sm: 8, md: 16, lg: 16 }}>
							<Col span={24}>
								<Space direction="horizontal">
									<Typography.Title level={3} style={{ margin: 0 }}>
										{classroom.name}
									</Typography.Title>
								</Space>
							</Col>
							<Col lg={24} md={24}>
								<Space
									direction="vertical"
									size="middle"
									className="layout-popup"
									style={{ width: "100%" }}>
									<Card title="Detail Kelas" size="small">
										<Descriptions layout="vertical" column={2}>
											<Descriptions.Item label="Tahun">{classroom.year.name}</Descriptions.Item>
											<Descriptions.Item label="Nama">{classroom.name}</Descriptions.Item>
											<Descriptions.Item label="Tingkat">{classroom.grade}</Descriptions.Item>
											<Descriptions.Item label="Wali Kelas">
												{classroom.teachers.length > 0 ? (
													classroom.teachers.map((teacher, index) => {
														return (
															<span key={index}>
																{teacher.name}
																<Tag
																	color="blue"
																	style={{ marginLeft: 4, marginRight: 0, fontWeight: "normal" }}>
																	Wali Kelas
																</Tag>
																<Popconfirm
																	placement="topRight"
																	title={`Anda yakin akan membebastugaskan ${teacher.name}?`}
																	onConfirm={(e) =>
																		handleUnassignTeacherButton(classroom._id, teacher._id)
																	}
																	okText="Yes"
																	cancelText="No">
																	<Button
																		type="link"
																		shape="default"
																		size="small"
																		icon={<CloseCircleFilled />}
																		danger
																	/>
																</Popconfirm>
															</span>
														);
													})
												) : (
													<Button
														type="dashed"
														size="small"
														onClick={() =>
															handleAssignTeacherButton(
																lessons.length > 0 ? lessons[0] : null,
																"CLASSROOM"
															)
														}>
														<PlusCircleFilled /> Tugaskan Wali Kelas
													</Button>
												)}
											</Descriptions.Item>
										</Descriptions>
									</Card>
									<Card title={"Aksi"} size="small">
										<Row gutter={[8, 8]}>
											<Col span={12}>
												<Button
													type="dashed"
													style={{ width: "100%" }}
													onClick={() => {
														setDailyReportVisible(true);
													}}>
													<HiClipboardDocumentCheck
														style={{
															marginRight: 4,
														}}
													/>{" "}
													Presensi Harian
												</Button>
											</Col>
											<Col span={12}>
												<Button
													type="dashed"
													style={{ width: "100%" }}
													onClick={() => {
														notification.warning({
															message: "Fitur belum tersedia",
															description: "Saat ini fitur masih dalam tahap pengembangan.",
														});
													}}>
													<HiClipboardDocumentList
														style={{
															marginRight: 4,
														}}
													/>{" "}
													Nilai Raport
												</Button>
											</Col>
										</Row>
									</Card>
									<Card title="Pelajaran" size="small">
										<Table
											size="small"
											columns={lessonColumns}
											dataSource={mappedLessons}
											loading={isLoading}
											bordered
											pagination={{
												position: "bottomRight",
												pageSizeOptions: ["10", "20", "50", "100"],
												showSizeChanger: true,
												locale: { items_per_page: "" },
											}}
											className="ant-border-space"
										/>
										<Typography.Text
											type="secondary"
											style={{
												display: "block",
											}}>
											Untuk dapat menambah atau menghapus pelajaran, silahkan ke menu
											<strong> pelajaran </strong>.
										</Typography.Text>
									</Card>
								</Space>
							</Col>
							<Col lg={24} md={24}>
								<Space
									direction="vertical"
									size="middle"
									className="layout-popup"
									style={{ width: "100%" }}>
									<Card
										title={
											<div
												style={{
													display: "flex",
													justifyContent: "space-between",
													alignItems: "center",
													gap: 8,
												}}>
												<span>Siswa</span>
												<Space wrap>
													<Radio.Group
														size="small"
														value={mode}
														onChange={(e) => handleModeChange(e.target.value)}
														style={{ float: "right" }}>
														<Radio.Button value="student">Siswa</Radio.Button>
														<Radio.Button value="invoice">Tagihan</Radio.Button>
														<Radio.Button value="attendance">Presensi</Radio.Button>
													</Radio.Group>
													{selectedStudentIds.length > 0 ? (
														<Dropdown.Button
															type="default"
															size="small"
															menu={actionMenuProps}
															trigger={["click"]}>
															<CheckCircleFilled /> {selectedStudentIds.length} Dipilih
														</Dropdown.Button>
													) : (
														<Button type="default" size="small" onClick={handleEnrollStudentButton}>
															<PlusCircleFilled /> Tambah
														</Button>
													)}
												</Space>
											</div>
										}
										size="small">
										<Table
											size="small"
											columns={columns}
											dataSource={mappedStudents}
											loading={isLoading}
											bordered
											pagination={{
												position: "bottomRight",
												pageSizeOptions: ["10", "20", "50", "100"],
												showSizeChanger: true,
												locale: { items_per_page: "" },
											}}
											className="ant-border-space"
											scroll={{ x: "max-content" }}
											rowSelection={{
												type: "checkbox",
												...{
													onChange: (selectedRowKeys, selectedRows) => {
														setSelectedStudentIds(selectedRowKeys.map((key) => key.toString()));
													},
												},
											}}
										/>
									</Card>
								</Space>
							</Col>
						</Row>
					)}
				</Spin>
			</Modal>
		</>
	);
};

export default ClassroomDetailModal;
