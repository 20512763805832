/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { Menu, Button, Collapse, Tag } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../../assets/images/logo.svg";
import { getActiveYear as adminGetActiveYear } from "../../services/admin.service";
import { requestGetActiveYear as teacherGetActiveYear } from "../../services/teacher.service";
import { useState } from "react";
import { CaretRightOutlined } from "@ant-design/icons";
import AppContext from "../../store/app.context";
import {
	HiAcademicCap,
	HiArrowPathRoundedSquare,
	HiBriefcase,
	HiBuildingLibrary,
	HiBuildingOffice,
	HiCalendarDays,
	HiChartBar,
	HiCog6Tooth,
	HiCreditCard,
	HiDocumentText,
	HiFolderOpen,
	HiMegaphone,
	HiReceiptPercent,
	HiRectangleGroup,
	HiTicket,
	HiUser,
} from "react-icons/hi2";

function Sidenav({ color }) {
	const context = useContext(AppContext);
	const { pathname } = useLocation();
	const page = pathname.replace("/", "");
	const [year, setYear] = useState({});
	const [role, setRole] = useState(
		context.role === "MASTER" ? "admin" : context.role.toLowerCase()
	);

	useEffect(() => {
		fetchActiveYear();
	}, [context.role]);

	const fetchActiveYear = () => {
		if (context.role === "ADMIN" || context.role === "MASTER") {
			adminGetActiveYear()
				.then((res) => {
					const year = res.data.data;
					setYear(year);
					context.setYear(year);
				})
				.catch((error) => {
					console.log(error);
				});
		} else if (context.role === "TEACHER") {
			teacherGetActiveYear()
				.then((res) => {
					const year = res.data.data;
					setYear(year);
					context.setYear(year);
				})
				.catch((error) => {
					console.log(error);
				});
		}
	};

	return (
		<>
			<div
				className="brand"
				style={{
					alignContent: "center",
					alignItems: "center",
					display: "flex",
					justifyContent: "center",
				}}>
				<img className="brand-logo" src={logo} alt="Smartiva" />
				<span>Smartiva Office</span>
			</div>
			<div style={{ paddingTop: 18, paddingBottom: 18 }}>
				<Collapse expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
					<Collapse.Panel header={year.name} key="1" className="year-panel">
						{/* Render semester */}
						<Menu theme="light" mode="inline">
							{year.semesters &&
								year.semesters.map((semester) => (
									<Menu.Item key="semester1">
										<NavLink
											to="/admin/semester1"
											style={{
												paddingTop: 5,
												paddingBottom: 5,
											}}>
											<span className="label">{semester.name}</span>
											{semester.status === "ACTIVE" && (
												<Tag style={{ marginLeft: 4 }} color="green">
													Aktif
												</Tag>
											)}
										</NavLink>
									</Menu.Item>
								))}
						</Menu>
					</Collapse.Panel>
				</Collapse>
			</div>
			<Menu theme="light" mode="inline">
				{role === "admin" && (
					<>
						<Menu.Item key="dashboard" style={{ width: "100%" }}>
							<NavLink to="/admin/dashboard">
								<span
									className="icon"
									style={{
										backgroundColor: page === "admin/dashboard" ? color : "",
									}}>
									<HiRectangleGroup />
								</span>
								<span className="label">Dasbor</span>
							</NavLink>
						</Menu.Item>
						<Menu.Item className="menu-item-header" key="application" style={{ width: "100%" }}>
							Aplikasi
						</Menu.Item>
						<Menu.Item key="users" style={{ width: "100%" }}>
							<NavLink to="/admin/users">
								<span
									className="icon"
									style={{
										background: page === `/admin/users` ? color : "",
									}}>
									<HiUser />
								</span>
								<span className="label">Pengguna</span>
							</NavLink>
						</Menu.Item>
						<Menu.Item key="announcements" style={{ width: "100%" }}>
							<NavLink to="/admin/announcements">
								<span
									className="icon"
									style={{
										background: page === `/admin/announcements` ? color : "",
									}}>
									<HiMegaphone />
								</span>
								<span className="label">Pengumuman</span>
							</NavLink>
						</Menu.Item>
						<Menu.Item className="menu-item-header" key="academic" style={{ width: "100%" }}>
							Akademik
						</Menu.Item>
						<Menu.Item key="years" style={{ width: "100%" }}>
							<NavLink to="/admin/years">
								<span
									className="icon"
									style={{
										background: page === "admin/years" ? color : "",
									}}>
									<HiBuildingLibrary />
								</span>
								<span className="label">Tahun</span>
							</NavLink>
						</Menu.Item>
						{/* <Menu.Item key="subjects" style={{ width: "100%" }}>
							<NavLink to="/admin/subjects">
								<span
									className="icon"
									style={{
										background: page === "admin/subjects" ? color : "",
									}}>
									<HiBookmark />
								</span>
								<span className="label">Matpel</span>
							</NavLink>
						</Menu.Item> */}
						<Menu.Item key="classrooms" style={{ width: "100%" }}>
							<NavLink to="/admin/classrooms">
								<span
									className="icon"
									style={{
										backgroundColor: page === "admin/classrooms" ? color : "",
									}}>
									<HiChartBar />
								</span>
								<span className="label">Kelas</span>
							</NavLink>
						</Menu.Item>
						<Menu.Item key="lessons" style={{ width: "100%" }}>
							<NavLink to="/admin/lessons">
								<span
									className="icon"
									style={{
										background: page === "admin/lessons" ? color : "",
									}}>
									<HiFolderOpen />
								</span>
								<span className="label">Pelajaran</span>
							</NavLink>
						</Menu.Item>
						<Menu.Item key="students" style={{ width: "100%" }}>
							<NavLink to="/admin/students">
								<span
									className="icon"
									style={{
										background: page === "admin/students" ? color : "",
									}}>
									<HiAcademicCap />
								</span>
								<span className="label">Siswa</span>
							</NavLink>
						</Menu.Item>
						<Menu.Item key="teachers" style={{ width: "100%" }}>
							<NavLink to="/admin/teachers">
								<span
									className="icon"
									style={{
										background: page === "admin/teachers" ? color : "",
									}}>
									<HiBriefcase />
								</span>
								<span className="label">Guru</span>
							</NavLink>
						</Menu.Item>
						<Menu.Item key="events" style={{ width: "100%" }}>
							<NavLink to="/admin/events">
								<span
									className="icon"
									style={{
										background: page === "admin/events" ? color : "",
									}}>
									<HiCalendarDays />
								</span>
								<span className="label">Kegiatan</span>
							</NavLink>
						</Menu.Item>

						<Menu.Item className="menu-item-header" key="finance" style={{ width: "100%" }}>
							Keuangan
						</Menu.Item>
						{/* Fees */}
						<Menu.Item key="fees" style={{ width: "100%" }}>
							<NavLink to="/admin/fees">
								<span
									className="icon"
									style={{
										background: page === "admin/fees" ? color : "",
									}}>
									<HiTicket />
								</span>
								<span className="label">Biaya</span>
							</NavLink>
						</Menu.Item>
						{/* Invoice */}
						<Menu.Item key="invoices" style={{ width: "100%" }}>
							<NavLink to="/admin/invoices">
								<span
									className="icon"
									style={{
										background: page === "admin/invoices" ? color : "",
									}}>
									<HiReceiptPercent />
								</span>
								<span className="label">Tagihan</span>
							</NavLink>
						</Menu.Item>
						{/* Transaction */}
						<Menu.Item key="transactions" style={{ width: "100%" }}>
							<NavLink to="/admin/transactions">
								<span
									className="icon"
									style={{
										background: page === "admin/transactions" ? color : "",
									}}>
									<HiArrowPathRoundedSquare />
								</span>
								<span className="label">Transaksi</span>
							</NavLink>
						</Menu.Item>
						{/* Pocket */}
						<Menu.Item key="pocket" style={{ width: "100%" }}>
							<NavLink to="/admin/pockets">
								<span
									className="icon"
									style={{
										background: page === "admin/pockets" ? color : "",
									}}>
									<HiCreditCard />
								</span>
								<span className="label">Tabungan</span>
							</NavLink>
						</Menu.Item>

						<Menu.Item className="menu-item-header" key="publications" style={{ width: "100%" }}>
							Publikasi
						</Menu.Item>
						<Menu.Item key="articles" style={{ width: "100%" }}>
							<NavLink to="/admin/articles">
								<span
									className="icon"
									style={{
										background: page === "admin/articles" ? color : "",
									}}>
									<HiDocumentText />
								</span>
								<span className="label">Artikel</span>
							</NavLink>
						</Menu.Item>

						<Menu.Item className="menu-item-header" key="other" style={{ width: "100%" }}>
							Lain-lain
						</Menu.Item>
						<Menu.Item key="settings" style={{ width: "100%" }}>
							<NavLink to="/admin/settings">
								<span
									className="icon"
									style={{
										background: page === "admin/settings" ? color : "",
									}}>
									<HiCog6Tooth />
								</span>
								<span className="label">Pengaturan</span>
							</NavLink>
						</Menu.Item>
					</>
				)}

				{role === "teacher" && (
					<>
						<Menu.Item key="dashboard" style={{ width: "100%" }}>
							<NavLink to="/teacher/dashboard">
								<span
									className="icon"
									style={{
										backgroundColor: page === "teacher/dashboard" ? color : "",
									}}>
									<HiRectangleGroup />
								</span>
								<span className="label">Dasbor</span>
							</NavLink>
						</Menu.Item>
						<Menu.Item className="menu-item-header" key="academic" style={{ width: "100%" }}>
							Akademik
						</Menu.Item>
						<Menu.Item key="classrooms" style={{ width: "100%" }}>
							<NavLink to="/teacher/classrooms">
								<span
									className="icon"
									style={{
										backgroundColor: page === "teacher/classrooms" ? color : "",
									}}>
									<HiBuildingOffice />
								</span>
								<span className="label">Kelas & Pelajaran</span>
							</NavLink>
						</Menu.Item>
						{/* <Menu.Item key="lessons" style={{ width: "100%" }}>
							<NavLink to="/teacher/lessons">
								<span
									className="icon"
									style={{
										background: page === "teacher/lessons" ? color : "",
									}}>
									<HiDocumentText />
								</span>
								<span className="label">Pelajaran</span>
							</NavLink>
						</Menu.Item> */}
						<Menu.Item key="events" style={{ width: "100%" }}>
							<NavLink to="/teacher/events">
								<span
									className="icon"
									style={{
										background: page === "teacher/events" ? color : "",
									}}>
									<HiCalendarDays />
								</span>
								<span className="label">Kegiatan</span>
							</NavLink>
						</Menu.Item>
					</>
				)}
			</Menu>
			<div className="aside-footer" style={{ paddingTop: 24 }}>
				<div
					className="footer-box"
					style={{
						background: color,
					}}>
					<span className="icon" style={{ color }}>
						<svg
							width={20}
							height={20}
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							strokeWidth={1.5}
							stroke="currentColor"
							className="w-6 h-6">
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
							/>
						</svg>
					</span>
					<h6>Ada pertanyaan?</h6>
					<p>Hubungi kami</p>
					<Button
						onClick={(e) => {
							e.preventDefault();
							window.location.href = "https://wa.me/6285155077455";
						}}
						type="primary"
						className="ant-btn-sm ant-btn-block">
						WhatsApp
					</Button>
				</div>
			</div>
		</>
	);
}

export default Sidenav;
