/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
	DeleteFilled,
	EditFilled,
	ExclamationCircleFilled,
	MoreOutlined,
	PlusCircleFilled,
} from "@ant-design/icons";
import { App, Button, Card, Col, Dropdown, Popconfirm, Row, Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import { deleteSubject, requestGetSubjectList } from "../../../services/admin.service";
import SubjectFormModal from "./components/SubjectFormModal";
import { HiOutlinePencil, HiOutlineTrash } from "react-icons/hi2";
import SearchInput from "../../../components/SearchInput";

const columns = [
	{
		title: "Nama",
		dataIndex: "name",
		key: "name",
		sorter: true,
	},
	{
		title: "Aksi",
		dataIndex: "actions",
		key: "actions",
		align: "right",
	},
];

const otherButtons = [
	// {
	// 	key: "edit",
	// 	label: "Edit",
	// 	icon: <HiOutlinePencil />,
	// },
	// {
	// 	type: "divider",
	// },
	// {
	// 	key: "delete",
	// 	label: "Hapus",
	// 	icon: <HiOutlineTrash />,
	// 	danger: true,
	// },
];

const SubjectPage = () => {
	const { message, notification, modal } = App.useApp();

	// SEARCH, FILTER, PAGINATION
	const [keyword, setKeyword] = useState("");
	const [filter, setFilter] = useState({});
	const [sorter, setSorter] = useState({
		_id: "desc",
	});
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);

	const handleSearch = (value) => {
		setKeyword(value.trim());
	};

	const handlePaginate = (pagination, filters, sorter) => {
		setPage(pagination.current);
		setLimit(pagination.pageSize);

		// UPDATE FILTER
		if (filters) {
			const additionalFilter = {};
			Object.keys(filters).forEach((key) => {
				if (filters[key]) {
					additionalFilter[key] = filters[key].join("|");
				} else {
					additionalFilter[key] = "";
				}
			});

			setFilter({
				...filter,
				...additionalFilter,
			});
		} else {
			const tempFilter = {};
			// only allow startedAt and endedAt
			Object.keys(filter).forEach((key) => {
				if (key === "startedAt" || key === "endedAt") {
					tempFilter[key] = filter[key];
				}
			});

			setFilter(tempFilter);
		}

		// UPDATE SORTER
		if (sorter) {
			setSorter({
				[sorter.field]: sorter.order === "ascend" ? "asc" : "desc",
			});
		} else {
			setSorter({
				_id: "desc",
			});
		}
	};
	// SEARCH, FILTER, PAGINATION

	const [subjects, setSubjects] = useState([]);
	const [mappedSubjects, setMappedSubjects] = useState([]);
	const [isLoading, setLoading] = useState(false);

	const [subject, setSubject] = useState(null);
	const [isSubjectFormVisible, setSubjectFormVisible] = useState(false);

	useEffect(() => {
		fetchSubjectList();
	}, [keyword, filter, page, limit]);

	const fetchSubjectList = () => {
		setLoading(true);
		let tempFilter = "";
		if (filter) {
			Object.keys(filter).forEach((key) => {
				if (filter[key]) {
					tempFilter += `${key}:${filter[key]},`;
				}
			});
		}

		let tempSorter = "";
		if (sorter) {
			Object.keys(sorter).forEach((key) => {
				if (sorter[key]) {
					tempSorter += `${key}:${sorter[key]}`;
				}
			});
		}

		requestGetSubjectList({
			page: page,
			limit: limit,
			order: tempSorter ?? "_id:desc",
			keyword: keyword ?? "",
			filter: tempFilter ?? "",
		})
			.then((response) => {
				setTotal(response.data.pagination.total);
				setSubjects(response.data.data);
				updateMappedSubject(response.data.data);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const updateMappedSubject = (data) => {
		setMappedSubjects(
			data.map((d) => ({
				key: d._id,
				name: d.name,
				actions: (
					<Space>
						<Dropdown
							trigger={["click"]}
							menu={{
								onClick: (e) => {
									if (e.key === "edit") {
										handleEditButton(d);
									} else if (e.key === "delete") {
										modal.confirm({
											title: "Sebentar",
											icon: <ExclamationCircleFilled />,
											content:
												"Anda yakin akan menghapus mata pelajaran ini? Tindakan ini tidak dapat dibatalkan. Semua data terkait akan terputus.",
											okText: "Ya",
											okType: "danger",
											cancelText: "Tidak",
											onOk() {
												handleDeleteButton(d._id);
											},
											onCancel() {},
										});
									}
								},
								items: otherButtons,
							}}
							placement="bottomRight"
							arrow>
							<Button type="default" shape="circle" size="small" icon={<MoreOutlined />} />
						</Dropdown>
					</Space>
				),
			}))
		);
	};

	const handleAddButton = () => {
		setSubjectFormVisible(true);
	};

	const handleEditButton = (subject) => {
		setSubject(subject);
		setSubjectFormVisible(true);
	};

	const handleDeleteButton = (id) => {
		setLoading(true);
		deleteSubject(id)
			.then((response) => {
				if (response.data.code === 200) {
					notification["success"]({
						message: "Good job!",
						description: response.data.message,
					});

					fetchSubjectList();
				}
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleOnCloseSubjectForm = () => {
		setSubject(null);
		setSubjectFormVisible(false);
	};

	const handleOnSuccessSubjectForm = () => {
		setSubject(null);
		setSubjectFormVisible(false);
		fetchSubjectList();
	};

	return (
		<>
			<SubjectFormModal
				isLoading={isLoading}
				isVisible={isSubjectFormVisible}
				onLoading={(v) => setLoading(v)}
				subject={subject}
				onClose={handleOnCloseSubjectForm}
				onSuccess={handleOnSuccessSubjectForm}
			/>
			<div className="tabled">
				<Row gutter={[24, 0]}>
					<Col xs={24} xl={24}>
						<Card
							bordered={false}
							className="circlebox tablespace mb-24"
							title="Daftar Mata Pelajaran"
							extra={
								<Space>
									<SearchInput placeholder="Nama mata pelajaran" onSearch={handleSearch} />
									{/* <Button icon={<PlusCircleFilled />} type="primary" onClick={handleAddButton}>
										<span className="ant-btn-text">Tambah</span>
									</Button> */}
								</Space>
							}>
							<div>
								<Table
									columns={columns}
									loading={isLoading}
									dataSource={mappedSubjects}
									pagination={{
										total: total,
										position: "bottomRight",
										pageSizeOptions: ["10", "20", "50", "100"],
										showSizeChanger: true,
										locale: { items_per_page: "item/hal" },
									}}
									onChange={handlePaginate}
									className="ant-border-space"
								/>
							</div>
						</Card>
					</Col>
				</Row>
			</div>
		</>
	);
};

export default SubjectPage;
