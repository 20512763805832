import { Descriptions, Image, Modal, Tag, Tooltip, Typography, message, notification } from "antd";
import dayjs from "dayjs";
import { format } from "../../../../utils/Formatter";
import { HiOutlineClipboard } from "react-icons/hi2";
import { useEffect, useState } from "react";
import { requestShowTransaction } from "../../../../services/admin.service";

const TransactionDetailModal = ({ isVisible, transactionId, onClose }) => {
	const [transaction, setTransaction] = useState(null);

	useEffect(() => {
		if (isVisible && transactionId) {
			requestShowTransaction(transactionId)
				.then((response) => {
					if (response.data) {
						setTransaction(response.data.data);
					} else {
						notification.error({
							message: "Gagal Memuat Data",
							description: response.message,
						});
					}
				})
				.catch((error) => {
					notification.error({
						message: "Gagal Memuat Data",
						description: error.message,
					});
				});
		} else {
			setTransaction(null);
		}
	}, [isVisible, transactionId]);

	return (
		<Modal
			width={512}
			title={
				<Typography.Text
					style={{
						display: "flex",
						alignItems: "center",
					}}
					copyable={{
						text: transaction?._id,
						tooltips: ["Salin nomor ID", "Berhasil disalin!"],
					}}>
					{transaction?._id}
				</Typography.Text>
			}
			open={isVisible}
			onCancel={onClose}
			footer={null}>
			{transaction && (
				<>
					<Descriptions size="small" bordered column={1}>
						<Descriptions.Item
							labelStyle={{
								width: 128,
							}}
							label="Nominal">
							{format(transaction?.nominal)}
						</Descriptions.Item>
						<Descriptions.Item
							labelStyle={{
								width: 128,
							}}
							label="Biaya Lain">
							{format(transaction?.fees?.map((fee) => fee.amount).reduce((a, b) => a + b, 0) ?? 0)}
						</Descriptions.Item>
						<Descriptions.Item
							labelStyle={{
								width: 128,
							}}
							label="Total">
							{format(
								transaction?.nominal +
									(transaction?.fees?.map((fee) => fee.amount).reduce((a, b) => a + b, 0) ?? 0)
							)}
						</Descriptions.Item>
					</Descriptions>
					<Descriptions
						size="small"
						style={{
							marginTop: 12,
						}}
						bordered
						column={1}>
						<Descriptions.Item
							labelStyle={{
								width: 128,
							}}
							label="Channel">
							{transaction?.channel}
						</Descriptions.Item>
						<Descriptions.Item
							labelStyle={{
								width: 128,
							}}
							label="Status">
							{transaction.status === "COMPLETED" ? (
								<Tag color="processing">Berhasil</Tag>
							) : transaction.status === "CREATED" ? (
								<Tag color="warning">Dibuat</Tag>
							) : transaction.status === "SUBMITTED" ? (
								<Tag color="warning">Pengecekan</Tag>
							) : transaction.status === "SETTLED" ? (
								<Tag color="success">Tuntas</Tag>
							) : transaction.status === "FAILED" ? (
								<Tag color="error">Gagal</Tag>
							) : (
								<Tag color="default">Tidak Sah</Tag>
							)}
						</Descriptions.Item>
						<Descriptions.Item
							labelStyle={{
								width: 128,
							}}
							label="Tanggal">
							{dayjs(transaction.createdAt).format("DD MMM YYYY")}
						</Descriptions.Item>
						<Descriptions.Item
							labelStyle={{
								width: 128,
							}}
							label="Keterangan">
							{transaction?.note}
						</Descriptions.Item>
						{/* Attachment */}
						{transaction?.attachments && (
							<Descriptions.Item
								labelStyle={{
									width: 128,
								}}
								label="Bukti Transfer">
								{transaction?.attachments.map((attachment) => (
									<Image
										key={attachment.path}
										src={attachment.url}
										alt="Bukti Transfer"
										style={{
											width: 128,
											height: 128,
											objectFit: "cover",
											marginTop: 8,
										}}
									/>
								))}
							</Descriptions.Item>
						)}
					</Descriptions>
				</>
			)}
		</Modal>
	);
};

export default TransactionDetailModal;
