import {
	App,
	Button,
	Checkbox,
	Descriptions,
	Dropdown,
	Form,
	Input,
	Modal,
	Popconfirm,
	Row,
	Select,
	Table,
	Tag,
	Tooltip,
	Typography,
} from "antd";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { MoreOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { requestReportEvent } from "../../../../services/teacher.service";

const LessonReportFormModal = ({
	lesson,
	event,
	isVisible,
	isLoading,
	onLoading,
	onClose,
	onSuccess,
}) => {
	const { notification } = App.useApp();
	const [form] = Form.useForm();
	const [students, setStudents] = useState([]);
	const [attendees, setAttendees] = useState([]);

	useEffect(() => {
		if (isVisible && event && lesson) {
			setStudents(lesson.students);
			form.setFieldsValue({
				note: event?.report?.note,
				isSigned: event?.report?.signedAt ? true : false,
			});
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isVisible, event, lesson]);

	useEffect(() => {
		if (students.length > 0) {
			updateAttendees();
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [students]);

	const updateAttendees = () => {
		const tempAttendees = students.map((item) => {
			const eventAttendee = event?.report?.attendees.find(
				(attendee) => attendee.referenceId === item._id
			);
			return {
				id: item._id,
				referenceId: item._id,
				type: "STUDENT",
				number: item.number,
				name: item.name,
				status: eventAttendee ? eventAttendee.status : "PRESENT",
				score: eventAttendee ? eventAttendee.score : 0,
				note: eventAttendee ? eventAttendee.note : "",
			};
		});
		setAttendees(tempAttendees);
	};

	const handleSubmit = () => {
		form
			.validateFields()
			.then((values) => {
				onLoading(true);
				values.attendees = attendees;
				values.isSigned = values.isSigned ? values.isSigned : false;

				requestReportEvent(event._id, values)
					.then((response) => {
						notification["success"]({
							message: "Berhasil!",
							description: "Laporan pertemuan berhasil disimpan",
						});
						onSuccess(lesson, response.data.data);
						handleClose();
					})
					.catch((error) => {
						if (error.response && error.response.data) {
							notification["error"]({
								message: "Kesalahan!",
								description: error.response.data.message,
							});
						} else {
							notification["error"]({
								message: "Kesalahan!",
								description: error.message,
							});
						}
					})
					.finally(() => {
						onLoading(false);
					});
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				onLoading(false);
			});
	};

	const handleClose = () => {
		form.resetFields();
		setAttendees([]);
		setStudents([]);
		onClose();
	};

	return (
		<>
			<Modal
				width={1024}
				title={"Laporan Pertemuan"}
				open={isVisible}
				cancelText="Batal"
				onCancel={handleClose}
				footer={[
					<Button key={1} onClick={handleClose}>
						Batal
					</Button>,
					<Popconfirm
						key={2}
						onConfirm={handleSubmit}
						title="Yakin akan menyimpan data laporan pertemuan?"
						okText="Ya"
						cancelText="Tidak">
						<Button type="primary">{"Simpan"}</Button>
					</Popconfirm>,
				]}>
				<Form layout="vertical" form={form} requiredMark="optional">
					<Descriptions size="small" bordered column={2}>
						<Descriptions.Item
							labelStyle={{
								width: 128,
							}}
							label="Pelajaran">
							{lesson?.subject.name}
						</Descriptions.Item>
						<Descriptions.Item
							labelStyle={{
								width: 128,
							}}
							label="Kelas">
							{lesson?.classroom.name}
						</Descriptions.Item>
						<Descriptions.Item
							labelStyle={{
								width: 128,
							}}
							label="Mulai">
							{dayjs(event?.startedAt).format("DD MMMM YYYY HH:mm")}
						</Descriptions.Item>
						<Descriptions.Item
							labelStyle={{
								width: 128,
							}}
							label="Selesai">
							{dayjs(event?.endedAt).format("DD MMMM YYYY HH:mm")}
						</Descriptions.Item>
						<Descriptions.Item
							labelStyle={{
								width: 128,
							}}
							label="Durasi">
							{dayjs(event?.endedAt).diff(dayjs(event?.startedAt), "minute")} menit
						</Descriptions.Item>
						<Descriptions.Item
							labelStyle={{
								width: 128,
							}}
							label="TTD">
							{event?.report?.signedAt ? (
								<Tag color="green">Sudah ditandatangani</Tag>
							) : (
								<Tag color="red">Belum ditandatangani</Tag>
							)}
						</Descriptions.Item>
					</Descriptions>
					<Typography.Paragraph
						style={{
							marginTop: 12,
							marginBottom: 0,
							paddingBottom: 8,
						}}>
						Daftar Siswa
						<Tooltip placement="top" title={"Siswa yang terdaftar pada kelas ini"}>
							<QuestionCircleOutlined
								style={{
									marginLeft: 4,
									color: "rgba(0, 0, 0, 0.45)",
								}}
							/>
						</Tooltip>
					</Typography.Paragraph>
					<Table
						rowKey="id"
						dataSource={attendees}
						pagination={false}
						loading={isLoading}
						size="small"
						bordered
						footer={null}>
						<Table.Column
							onCell={(record, rowIndex) => {
								return {
									style: {
										backgroundColor:
											record.status === "ABSENT"
												? "#ff4d4f"
												: record.status === "LATE"
												? "#ffc069"
												: record.status === "EXCUSED"
												? "#bae637"
												: "#52c41a",
									},
								};
							}}
							title="ID"
							dataIndex="number"
							width={50}
							align="center"
							render={(text, record, index) => {
								return (
									<span
										style={{
											fontWeight: "bold",
											color: "#fff",
										}}>
										{text}
									</span>
								);
							}}
						/>
						<Table.Column title="Nama" dataIndex="name" width={200} />
						<Table.Column
							title={
								<Row justify="space-between">
									<span>Kehadiran</span>
									{/* mark all as */}
									<Tooltip placement="top" title={"Tandai semua kehadiran siswa"}>
										<Dropdown
											menu={{
												items: [
													{
														key: 1,
														label: "Tandai sbg alpa",
														onClick: () => {
															const tempAttendees = [...attendees];
															tempAttendees.forEach((item) => {
																item.status = "ABSENT";
															});
															setAttendees(tempAttendees);
														},
													},
													{
														key: 2,
														label: "Tandai sbg hadir",
														onClick: () => {
															const tempAttendees = [...attendees];
															tempAttendees.forEach((item) => {
																item.status = "PRESENT";
															});
															setAttendees(tempAttendees);
														},
													},
													{
														key: 3,
														label: "Tandai sbg terlambat",
														onClick: () => {
															const tempAttendees = [...attendees];
															tempAttendees.forEach((item) => {
																item.status = "LATE";
															});
															setAttendees(tempAttendees);
														},
													},
													{
														key: 4,
														label: "Tandai sbg izin",
														onClick: () => {
															const tempAttendees = [...attendees];
															tempAttendees.forEach((item) => {
																item.status = "EXCUSED";
															});
															setAttendees(tempAttendees);
														},
													},
												],
											}}
											placement="bottomCenter"
											trigger={["click"]}
											arrow>
											<Button type="default" shape="circle" size="small" icon={<MoreOutlined />} />
										</Dropdown>
									</Tooltip>
								</Row>
							}
							dataIndex="status"
							width={120}
							render={(text, record, index) => {
								return (
									<Select
										size="small"
										defaultValue={0}
										style={{
											width: "100%",
										}}
										value={attendees[index].status}
										onChange={(value) => {
											const tempAttendees = [...attendees];
											tempAttendees[index].status = value;
											setAttendees(tempAttendees);
										}}>
										<Select.Option value="ABSENT">Alpa</Select.Option>
										<Select.Option value="PRESENT">Hadir</Select.Option>
										<Select.Option value="LATE">Terlambat</Select.Option>
										<Select.Option value="EXCUSED">Izin</Select.Option>
									</Select>
								);
							}}
						/>
						<Table.Column
							title="Nilai"
							dataIndex="score"
							width={60}
							render={(text, record, index) => {
								return (
									<Input
										size="small"
										value={attendees[index].score}
										onChange={(e) => {
											const tempAttendees = [...attendees];
											tempAttendees[index].score = e.target.value ? parseFloat(e.target.value) : 0;
											setAttendees(tempAttendees);
										}}
									/>
								);
							}}
						/>
						<Table.Column
							title="Keterangan"
							dataIndex="note"
							width={200}
							render={(text, record, index) => {
								return (
									<Input
										size="small"
										value={attendees[index].note}
										onChange={(e) => {
											const tempAttendees = [...attendees];
											tempAttendees[index].note = e.target.value;
											setAttendees(tempAttendees);
										}}
									/>
								);
							}}
						/>
					</Table>
					<Form.Item
						label="Keterangan"
						style={{ marginTop: 12, marginBottom: 0 }}
						name="note"
						tooltip="Masukkan keterangan kegiatan">
						<Form.Item name="note" noStyle>
							<Input.TextArea rows={4} placeholder="Masukkan keterangan pertemuan" />
						</Form.Item>
					</Form.Item>
					{/* checkbox is report is signed */}
					<Form.Item
						style={{ marginTop: 12, marginBottom: 0 }}
						name="isSigned"
						valuePropName="checked">
						<Checkbox>Tandai laporan pertemuan ini sudah ditandatangani oleh guru</Checkbox>
					</Form.Item>
				</Form>
			</Modal>
		</>
	);
};

export default LessonReportFormModal;
