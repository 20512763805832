/* eslint-disable react-hooks/exhaustive-deps */
import { App, Button, DatePicker, Form, Input, Modal, Popconfirm, Spin } from "antd";
import React, { useEffect } from "react";
import {
	requestAddAnnouncement,
	requestEditAnnouncement,
} from "../../../../services/admin.service";
import ReactQuill from "react-quill";
import dayjs from "dayjs";

const AnnouncementFormModal = ({
	announcement,
	isVisible,
	isLoading,
	onLoading,
	onClose,
	onSuccess,
}) => {
	const { message, notification, modal } = App.useApp();

	const [form] = Form.useForm();

	useEffect(() => {
		if (announcement !== null) {
			form.setFieldsValue({
				title: announcement.title,
				description: announcement.description,
				content: announcement.content,
				expiredAt: announcement.expiredAt ? dayjs(announcement.expiredAt) : null,
			});
		}
	}, [announcement]);

	const handleSubmit = () => {
		form
			.validateFields()
			.then((values) => {
				onLoading(true);
				if (announcement) {
					values.id = announcement._id;
					requestEditAnnouncement(announcement._id, values)
						.then((response) => {
							notification["success"]({
								message: "Good job!",
								description: response.data.message,
							});
							form.resetFields();
							onSuccess();
						})
						.catch((error) => {
							if (error.response && error.response.data) {
								notification["error"]({
									message: "Kesalahan!",
									description: error.response.data.message,
								});
							} else {
								notification["error"]({
									message: "Kesalahan!",
									description: error.message,
								});
							}
						})
						.finally(() => {
							onLoading(false);
						});
				} else {
					requestAddAnnouncement(values)
						.then((response) => {
							notification["success"]({
								message: "Good job!",
								description: response.data.message,
							});
							form.resetFields();
							onSuccess();
						})
						.catch((error) => {
							if (error.response && error.response.data) {
								notification["error"]({
									message: "Kesalahan!",
									description: error.response.data.message,
								});
							} else {
								notification["error"]({
									message: "Kesalahan!",
									description: error.message,
								});
							}
						})
						.finally(() => {
							onLoading(false);
						});
				}
			})
			.catch((error) => {});
	};

	const handleClose = () => {
		form.resetFields();
		onClose();
	};

	return (
		<>
			<Modal
				width={512}
				title={
					announcement === null || announcement === undefined
						? "Tambah Pengumuman"
						: "Ubah Pengumuman"
				}
				open={isVisible}
				cancelText="Batal"
				onCancel={handleClose}
				footer={[
					<Button key={1} onClick={handleClose}>
						Batal
					</Button>,
					<Popconfirm
						key={2}
						onConfirm={handleSubmit}
						title="Yakin akan menyimpan data pengumuman?"
						okText="Ya"
						cancelText="Tidak">
						<Button type="primary">{announcement ? "Simpan" : "Tambah"}</Button>
					</Popconfirm>,
				]}>
				<Spin spinning={isLoading}>
					<Form form={form} layout="vertical" requiredMark="optional">
						<Form.Item
							style={{ marginTop: 12, marginBottom: 0 }}
							name="title"
							label="Judul"
							required
							rules={[{ required: true, message: "Masukkan judul pengumuman!" }]}
							tooltip="Masukkan judul pengumuman">
							<Input style={{ width: "100%" }} />
						</Form.Item>
						{/* Description */}
						<Form.Item
							style={{ marginTop: 12, marginBottom: 0 }}
							name="description"
							label="Deskripsi"
							required
							rules={[{ required: true, message: "Masukkan deskripsi pengumuman!" }]}
							tooltip="Masukkan deskripsi pengumuman">
							<Input.TextArea rows={2} style={{ width: "100%" }} />
						</Form.Item>
						<Form.Item
							style={{ marginTop: 12, marginBottom: 0 }}
							name="content"
							label="Isi"
							required
							rules={[{ required: true, message: "Masukkan isi pengumuman!" }]}
							tooltip="Masukkan isi pengumuman">
							<ReactQuill
								onChange={(value) => {
									form.setFieldsValue({
										content: value,
									});
								}}
								theme="snow"
								style={{
									width: "100%",
									borderRadius: 4,
								}}
							/>
						</Form.Item>
						{/* Nominal */}
						<Form.Item
							style={{ marginTop: 12, marginBottom: 0 }}
							name="expiredAt"
							label="Kadaluarsa"
							tooltip="Masukkan tanggal kadaluarsa">
							<DatePicker style={{ width: "100%" }} />
						</Form.Item>
					</Form>
				</Spin>
			</Modal>
		</>
	);
};

export default AnnouncementFormModal;
