import { CalendarFilled } from "@ant-design/icons";
import { Button, Dropdown, Menu, Tag } from "antd";
import React from "react";

export const YearFilter = ({
	activeYear,
	years,
	filterBy,
	filteredBy,
	resettable = false,
	...props
}) => {
	const onClick = ({ key }) => {
		filterBy(key);
	};

	const items = [
		...years.map((year) => {
			return {
				key: year._id,
				label: (
					<div onClick={() => filterBy(year._id)}>
						{year.name}{" "}
						{year._id === activeYear._id && (
							<Tag style={{ marginLeft: 4 }} color="green">
								Aktif
							</Tag>
						)}
					</div>
				),
			};
		}),
	];

	return (
		<div {...props}>
			<Dropdown
				className="filter"
				trigger={["click"]}
				menu={{
					items: items,
					onClick: onClick,
				}}>
				<Button
					type={filteredBy.value !== "" ? "primary" : "default"}
					icon={<CalendarFilled />}
					className="ant-dropdown-link btn-filter">
					<span className="btn-filter-text">
						{filteredBy.value !== "" ? `: ${filteredBy.value}` : "Tidak Ada Yg Dipilih"}
					</span>
				</Button>
			</Dropdown>
		</div>
	);
};
