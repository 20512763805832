import axios from "axios";

// MODULE
export async function requestGetModuleList({
	page = 1,
	limit = 10,
	order = "name:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/admin/modules?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}
// MODULE

// ANNOUNCEMENT
export async function requestGetAnnouncementList({
	page = 1,
	limit = 10,
	order = "name:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/admin/announcements?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestAddAnnouncement(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/admin/announcements/add`, data);
}

export async function requestEditAnnouncement(id, data) {
	return await axios.put(`${process.env.REACT_APP_API_URL}/admin/announcements/${id}/edit`, data);
}

export async function requestDeleteAnnouncement(id) {
	return await axios.delete(`${process.env.REACT_APP_API_URL}/admin/announcements/${id}/delete`);
}
// ANNOUNCEMENT

// SCHOOL
export async function requestShowSchool({ join = "" }) {
	return await axios.get(`${process.env.REACT_APP_API_URL}/admin/schools/us?join=${join}`);
}
export async function requestUpdateSchool(data) {
	return await axios.put(`${process.env.REACT_APP_API_URL}/admin/schools/us/update`, data);
}
// SCHOOL

// YEAR
export async function requestGetYearList({
	page = 1,
	limit = 10,
	order = "name:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/admin/years?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function addYear(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/admin/years/add`, data);
}

export async function editYear(data) {
	return await axios.put(`${process.env.REACT_APP_API_URL}/admin/years/edit`, data);
}

export async function deleteYear(id) {
	return await axios.delete(`${process.env.REACT_APP_API_URL}/admin/years/delete`, {
		data: { id },
	});
}

export async function getActiveYear() {
	return await axios.get(`${process.env.REACT_APP_API_URL}/admin/years/active`);
}
// YEAR

// SUBJECT
export async function requestGetSubjectList({
	page = 1,
	limit = 10,
	order = "name:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/admin/subjects?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function addSubject(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/admin/subjects/add`, data);
}

export async function editSubject(data) {
	return await axios.put(`${process.env.REACT_APP_API_URL}/admin/subjects/edit`, data);
}

export async function deleteSubject(id) {
	return await axios.delete(`${process.env.REACT_APP_API_URL}/admin/subjects/delete`, {
		data: { id },
	});
}
// SUBJECT

// CLASSROOM
export async function requestGetClassroomList({
	page = 1,
	limit = 10,
	order = "name:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/admin/classrooms?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function showClassroom(id) {
	return await axios.get(`${process.env.REACT_APP_API_URL}/admin/classrooms/${id}`);
}

export async function addClassroom(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/admin/classrooms/add`, data);
}

export async function editClassroom(data) {
	return await axios.put(`${process.env.REACT_APP_API_URL}/admin/classrooms/${data.id}/edit`, data);
}

export async function deleteClassroom(id) {
	return await axios.delete(`${process.env.REACT_APP_API_URL}/admin/classrooms/delete`, {
		data: { id },
	});
}

export async function requestEnrollStudentsToClassroom(id, data) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/admin/classrooms/${id}/students/enroll`,
		data
	);
}

export async function requestUnenrollStudentsFromClassroom(id, data) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/admin/classrooms/${id}/students/unenroll`,
		data
	);
}

export async function requestAssignTeacherToClassroom(id, data) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/admin/classrooms/${id}/teachers/assign`,
		data
	);
}

export async function requestUnassignTeacherFromClassroom(id, data) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/admin/classrooms/${id}/teachers/unassign`,
		data
	);
}

export async function requestCloneClassroom(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/admin/classrooms/clone`, data);
}
// CLASSROOM

// LESSON
export async function requestGetLessonList({
	page = 1,
	limit = 10,
	order = "name:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/admin/lessons?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestShowLesson(id) {
	return await axios.get(`${process.env.REACT_APP_API_URL}/admin/lessons/${id}`);
}

export async function addLesson(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/admin/lessons/add`, data);
}

export async function editLesson(data) {
	return await axios.put(`${process.env.REACT_APP_API_URL}/admin/lessons/edit`, data);
}

export async function deleteLesson(id) {
	return await axios.delete(`${process.env.REACT_APP_API_URL}/admin/lessons/delete`, {
		data: { id },
	});
}
export async function requestScheduleLesson(id, data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/admin/lessons/${id}/schedule`, data);
}

export async function requestAssignTeacherToLesson(id, data) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/admin/lessons/${id}/teachers/assign`,
		data
	);
}

export async function requestUnassignTeacherFromLesson(id, data) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/admin/lessons/${id}/teachers/unassign`,
		data
	);
}
// LESSON

// STUDENT
export async function requestGetStudentList({
	page = 1,
	limit = 10,
	order = "name:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/admin/students?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestShowStudent(id) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/admin/students/show`, { id });
}

export async function addStudent(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/admin/students/add`, data);
}

export async function editStudent(data) {
	return await axios.put(`${process.env.REACT_APP_API_URL}/admin/students/edit`, data);
}

export async function deleteStudent(id) {
	return await axios.delete(`${process.env.REACT_APP_API_URL}/admin/students/delete`, {
		data: { id },
	});
}

export async function importStudentList(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/admin/students/import`, data);
}

export async function requestBillStudent(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/admin/students/bill`, data);
}

export async function requestPromoteStudents(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/admin/students/promote`, data);
}
// STUDENT

// TEACHER
export async function requestGetTeacherList({
	page = 1,
	limit = 10,
	order = "_id:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/admin/teachers?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function addTeacher(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/admin/teachers/add`, data);
}

export async function editTeacher(data) {
	return await axios.put(`${process.env.REACT_APP_API_URL}/admin/teachers/edit`, data);
}

export async function deleteTeacher(id) {
	return await axios.delete(`${process.env.REACT_APP_API_URL}/admin/teachers/delete`, {
		data: { id },
	});
}

export async function importTeacherList(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/admin/teachers/import`, data);
}
// TEACHER

// EVENT
export async function getEventList(start, end, filter = "type:LESSON|EXTRA|OTHER") {
	return await axios.get(
		`${process.env.REACT_APP_API_URL}/admin/events?order=startedAt:asc&start=${start}&end=${end}&filter=${filter}`
	);
}

export async function requestGetEventList({
	page = 1,
	limit = 10,
	order = "_id:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/admin/events?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestShowEvent(id) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/admin/events/${id}`);
}

export async function requestAddEvent(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/admin/events/add`, data);
}

export async function requestEditEvent(id, data) {
	return await axios.put(`${process.env.REACT_APP_API_URL}/admin/events/${id}/edit`, data);
}

export async function requestDeleteEvent(id) {
	return await axios.delete(`${process.env.REACT_APP_API_URL}/admin/events/${id}/delete`);
}

export async function requestReportEvent(id, data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/admin/events/${id}report`, data);
}
// EVENT

// FEE
export async function requestGetFeeList({
	page = 1,
	limit = 10,
	order = "_id:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/admin/fees?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function addFee(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/admin/fees/add`, data);
}

export async function editFee(data) {
	return await axios.put(`${process.env.REACT_APP_API_URL}/admin/fees/edit`, data);
}

export async function deleteFee(id) {
	return await axios.delete(`${process.env.REACT_APP_API_URL}/admin/fees/delete`, {
		data: { id },
	});
}
// FEE

// INVOICE
export async function requestGetInvoiceList({
	page = 1,
	limit = 10,
	order = "_id:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/admin/invoices?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestAddInvoice(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/admin/invoices/add`, data);
}

export async function requestEditInvoice(data) {
	return await axios.put(`${process.env.REACT_APP_API_URL}/admin/invoices/edit`, data);
}

export async function requestUpdateInvoiceStatus(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/admin/invoices/update-status`, data);
}

export async function requestGenerateInvoices(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/admin/invoices/generate`, data);
}
// INVOICE

// TRANSACTION
export async function requestGetTransactionList({
	page = 1,
	limit = 10,
	order = "_id:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/admin/transactions?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestAddTransaction(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/admin/transactions/add`, data);
}

export async function requestUpdateTransactionStatus(data) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/admin/transactions/update-status`,
		data
	);
}

export async function requestShowTransaction(id) {
	return await axios.get(`${process.env.REACT_APP_API_URL}/admin/transactions/${id}`);
}
// TRANSACTION

// USER
export async function requestGetUserList({
	page = 1,
	limit = 10,
	order = "_id:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/admin/users?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestAddUser(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/admin/users/add`, data);
}

export async function requestEditUser(data) {
	return await axios.put(`${process.env.REACT_APP_API_URL}/admin/users/edit`, data);
}
// USER

// ARTICLE
export async function requestGetArticleList({
	page = 1,
	limit = 10,
	order = "_id:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/admin/articles?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestAddArticle(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/admin/articles/add`, data);
}

export async function requestEditArticle(slug, data) {
	return await axios.put(`${process.env.REACT_APP_API_URL}/admin/articles/${slug}/edit`, data);
}

export async function requestDeleteArticle(slug) {
	return await axios.delete(`${process.env.REACT_APP_API_URL}/admin/articles/${slug}/delete`);
}
// ARTICLE

// POCKET
export async function requestGetPocketList({
	page = 1,
	limit = 10,
	order = "_id:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/admin/@pocket-module/pockets?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestBlockPocket(id) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/admin/@pocket-module/pockets/${id}/block`
	);
}

export async function requestUnblockPocket(id) {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/admin/@pocket-module/pockets/${id}/unblock`
	);
}
// POCKET
