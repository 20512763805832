/* eslint-disable react-hooks/exhaustive-deps */
import {
	Button,
	Col,
	DatePicker,
	Form,
	Input,
	Modal,
	App,
	Popconfirm,
	Row,
	Spin,
	Typography,
} from "antd";
import React, { useState, useEffect } from "react";
import { addYear, editYear } from "../../../../services/admin.service";
import { PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

const YearFormModal = ({ year, isVisible, isLoading, onLoading, onClose, onSuccess }) => {
	const { notification } = App.useApp();

	const [form] = Form.useForm();
	const [startedAt, setstartedAt] = useState(dayjs());
	const [endedAt, setendedAt] = useState(dayjs());
	const semesters = Form.useWatch("semesters", form);

	useEffect(() => {
		// set semester as 2 by default
		if (!semesters || semesters.length === 0) {
			form.setFieldsValue({
				semesters: [
					{
						name: "Ganjil",
					},
					{
						name: "Genap",
					},
				],
			});
		}
	}, [semesters]);

	useEffect(() => {
		if (year !== null) {
			// populate semesters
			const semesters = year.semesters.map((semester) => {
				return {
					...semester,
					startedAt: dayjs(semester.startedAt),
					endedAt: dayjs(semester.endedAt),
				};
			});

			form.setFieldsValue({
				name: year.name,
				startedAt: dayjs(year.startedAt),
				endedAt: dayjs(year.endedAt),
				semesters: semesters,
			});
			setstartedAt(dayjs(year.startedAt).format("YYYY-MM-DD"));
			setendedAt(dayjs(year.endedAt).format("YYYY-MM-DD"));
		} else {
			form.resetFields();
			form.setFieldValue("semesters", []);
		}
	}, [year]);

	const handleSubmit = () => {
		form
			.validateFields()
			.then((values) => {
				values.startedAt = dayjs(values.startedAt).format("YYYY-MM-DD");
				values.endedAt = dayjs(values.endedAt).format("YYYY-MM-DD");

				const semesters = values.semesters.map((semester) => {
					return {
						...semester,
						startedAt: dayjs(semester.startedAt).format("YYYY-MM-DD"),
						endedAt: dayjs(semester.endedAt).format("YYYY-MM-DD"),
					};
				});
				values.semesters = semesters;

				onLoading(true);
				if (year) {
					values.id = year._id;
					editYear(values)
						.then((response) => {
							notification["success"]({
								message: "Good job!",
								description: response.data.message,
							});
							form.resetFields();
							onSuccess();
						})
						.catch((error) => {
							if (error.response && error.response.data) {
								notification["error"]({
									message: "Kesalahan!",
									description: error.response.data.message,
								});
							} else {
								notification["error"]({
									message: "Kesalahan!",
									description: error.message,
								});
							}
						})
						.finally(() => {
							onLoading(false);
						});
				} else {
					addYear(values)
						.then((response) => {
							notification["success"]({
								message: "Good job!",
								description: response.data.message,
							});
							form.resetFields();
							onSuccess();
						})
						.catch((error) => {
							if (error.response && error.response.data) {
								notification["error"]({
									message: "Kesalahan!",
									description: error.response.data.message,
								});
							} else {
								notification["error"]({
									message: "Kesalahan!",
									description: error.message,
								});
							}
						})
						.finally(() => {
							onLoading(false);
						});
				}
			})
			.catch((error) => {});
	};

	const handleClose = () => {
		form.resetFields();
		onClose();
	};

	const onstartedAtChange = (date, dateString) => {
		setstartedAt(dateString);
	};

	const onendedAtChange = (date, dateString) => {
		setendedAt(dateString);
	};

	const disabledDate = (current) => {
		return current <= dayjs(startedAt).startOf("day") || current >= dayjs(endedAt).endOf("day");
	};

	return (
		<>
			<Modal
				width={512}
				title={
					year === null || year === undefined ? "Tambah Tahun Akademik" : "Ubah Tahun Akademik"
				}
				open={isVisible}
				cancelText="Batal"
				onCancel={handleClose}
				footer={[
					<Button key={1} onClick={handleClose}>
						Batal
					</Button>,
					<Popconfirm
						key={2}
						onConfirm={handleSubmit}
						title="Yakin akan menyimpan data tahun akademik?"
						okText="Ya"
						cancelText="Tidak">
						<Button type="primary">{year ? "Simpan" : "Tambah"}</Button>
					</Popconfirm>,
				]}>
				<Spin spinning={isLoading}>
					<Form form={form} layout="vertical" requiredMark="optional">
						<Form.Item
							style={{ marginTop: 0, marginBottom: 0 }}
							name="name"
							label="Nama"
							required
							rules={[{ required: true, message: "Nama tahun akademik harus diisi!" }]}
							tooltip="Masukkan nama tahun akademik">
							<Input style={{ width: "100%" }} />
						</Form.Item>
						<Form.Item
							style={{ marginTop: 12, marginBottom: 0 }}
							name="startedAt"
							label="Tanggal Mulai"
							required
							rules={[{ required: true, message: "Tanggal mulai harus diisi!" }]}
							tooltip="Masukkan tanggal mulai tahun akademik">
							<DatePicker style={{ width: "100%" }} onChange={onstartedAtChange} />
						</Form.Item>
						<Form.Item
							style={{ marginTop: 12, marginBottom: 0 }}
							name="endedAt"
							label="Tanggal Akhir"
							required
							rules={[{ required: true, message: "Tanggal akhir harus diisi!" }]}
							tooltip="Masukkan tanggal akhir tahun akademik">
							<DatePicker style={{ width: "100%" }} onChange={onendedAtChange} />
						</Form.Item>
						<Typography.Paragraph
							style={{
								marginTop: 12,
								marginBottom: 0,
								padding: "0 0 8px",
							}}>
							Semester
						</Typography.Paragraph>
						<Form.List name="semesters" label="Bahan" style={{ marginTop: 0, marginBottom: 12 }}>
							{(semesters, { add, remove }) => (
								<>
									{semesters.map(({ key, name, ...restField }, itemIndex) => (
										<Row gutter={8} key={key} align="baseline">
											<Col span={8}>
												<Form.Item
													style={{ marginTop: 0, marginBottom: 12 }}
													{...restField}
													name={[name, "name"]}
													rules={[{ required: true, message: "Nama harus diisi!" }]}>
													<Input disabled placeholder="Nama" />
												</Form.Item>
											</Col>
											<Col span={8}>
												<Form.Item
													requiredMark
													style={{ marginTop: 0, marginBottom: 12 }}
													{...restField}>
													<Form.Item
														requiredMark
														initialValue={dayjs()}
														name={[name, "startedAt"]}
														style={{
															width: "100%",
															marginTop: 0,
															marginBottom: 0,
														}}
														rules={[{ required: true, message: "Tanggal mulai harus diisi ya!" }]}>
														<DatePicker
															disabledDate={disabledDate}
															placeholder="Tanggal Mulai"
															style={{
																width: "100%",
																marginTop: 0,
																marginBottom: 0,
															}}
														/>
													</Form.Item>
												</Form.Item>
											</Col>
											<Col span={8}>
												<Form.Item
													requiredMark
													style={{ marginTop: 0, marginBottom: 12 }}
													{...restField}>
													<Form.Item
														requiredMark
														initialValue={dayjs()}
														name={[name, "endedAt"]}
														style={{
															width: "100%",
															marginTop: 0,
															marginBottom: 0,
														}}
														rules={[{ required: true, message: "Tanggal akhir harus diisi ya!" }]}>
														<DatePicker
															disabledDate={disabledDate}
															placeholder="Tanggal Akhir"
															style={{
																width: "100%",
																marginTop: 0,
																marginBottom: 0,
															}}
														/>
													</Form.Item>
												</Form.Item>
											</Col>
											{/* <Col span={2}>
												<Button
													icon={<DeleteOutlined />}
													onClick={() => remove(name)}
													danger></Button>
											</Col> */}
										</Row>
									))}
									{semesters.length < 2 && (
										<Form.Item style={{ marginTop: 0, marginBottom: 12 }}>
											<Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
												Tambah Semester
											</Button>
										</Form.Item>
									)}
								</>
							)}
						</Form.List>
					</Form>
				</Spin>
			</Modal>
		</>
	);
};

export default YearFormModal;
