import React, { useContext } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import Tables from "./pages/admin/Tables";
import Main from "./components/layout/Main";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import "react-quill/dist/quill.snow.css";
import AppContext from "./store/app.context";
import axios from "axios";
import RegisterPage from "./pages/auth/RegisterPage";
import LoginPage from "./pages/auth/LoginPage";
import DashboardPage from "./pages/admin/dashboard";
import SettingPage from "./pages/settings/SettingPage";
import AnnouncementPage from "./pages/admin/announcements";
import YearPage from "./pages/admin/years";
import SubjectPage from "./pages/admin/subjects";
import ClassroomPage from "./pages/admin/classrooms";
import LessonPage from "./pages/admin/lessons";
import StudentPage from "./pages/admin/students";
import TeacherPage from "./pages/admin/teachers";
import EventPage from "./pages/admin/events";
import FeePage from "./pages/admin/fees";
import InvoicePage from "./pages/admin/invoices";
import TransactionPage from "./pages/admin/transactions";
import UserPage from "./pages/admin/users";
import ForgotPasswordPage from "./pages/auth/ForgotPasswordPage";
import TeacherDashboardPage from "./pages/teacher/dashboard";
import TeacherYearPage from "./pages/teacher/years";
import TeacherClassroomPage from "./pages/teacher/classrooms";
import TeacherLessonPage from "./pages/teacher/lessons";
import TeacherEventPage from "./pages/teacher/events";
import dayjs from "dayjs";
import ArticlePage from "./pages/admin/articles";
import PocketPage from "./pages/admin/pockets";
require("dayjs/locale/id");

dayjs.extend(require("dayjs/plugin/utc"));
dayjs.extend(require("dayjs/plugin/timezone"));

dayjs.locale("id");
dayjs.tz.setDefault("Asia/Jakarta");

function App() {
	const context = useContext(AppContext);
	const navigate = useNavigate();

	axios.defaults.withCredentials = true;
	axios.defaults.headers.get["X-Language"] = "id";
	axios.defaults.headers.get["X-Timezone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;
	axios.defaults.headers.post["X-Language"] = "id";
	axios.defaults.headers.post["X-Timezone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;
	axios.defaults.headers.patch["X-Language"] = "id";
	axios.defaults.headers.patch["X-Timezone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;
	axios.defaults.headers.delete["X-Language"] = "id";
	axios.defaults.headers.delete["X-Timezone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;
	axios.interceptors.response.use(
		function (response) {
			return response;
		},
		function (error) {
			console.log(error.response);
			if (error.response.status === 401) {
				context.logout();
				navigate("/login");
			}
			return Promise.reject(error);
		}
	);

	return (
		<div className="App">
			<Routes>
				{!context.isLoggedIn && <Route path="/register" exact element={<RegisterPage />} />}
				{!context.isLoggedIn && <Route path="/login" exact element={<LoginPage />} />}
				{!context.isLoggedIn && (
					<Route path="/forgot-password" exact element={<ForgotPasswordPage />} />
				)}

				{/* Admin Start */}
				{context.isLoggedIn && (context.role === "MASTER" || context.role === "ADMIN") && (
					<>
						<Route
							exact
							path="/admin/dashboard"
							element={
								<Main>
									<DashboardPage />
								</Main>
							}
						/>
						<Route
							exact
							path="/admin/users"
							element={
								<Main>
									<UserPage />
								</Main>
							}
						/>
						<Route
							exact
							path="/admin/announcements"
							element={
								<Main>
									<AnnouncementPage />
								</Main>
							}
						/>
						<Route
							exact
							path="/admin/years"
							element={
								<Main>
									<YearPage />
								</Main>
							}
						/>
						<Route
							exact
							path="/admin/subjects"
							element={
								<Main>
									<SubjectPage />
								</Main>
							}
						/>
						<Route
							exact
							path="/admin/classrooms"
							element={
								<Main>
									<ClassroomPage />
								</Main>
							}
						/>
						<Route
							exact
							path="/admin/lessons"
							element={
								<Main>
									<LessonPage />
								</Main>
							}
						/>
						<Route
							exact
							path="/admin/students"
							element={
								<Main>
									<StudentPage />
								</Main>
							}
						/>
						<Route
							exact
							path="/admin/teachers"
							element={
								<Main>
									<TeacherPage />
								</Main>
							}
						/>
						<Route
							exact
							path="/admin/events"
							element={
								<Main>
									<EventPage />
								</Main>
							}
						/>
						<Route
							exact
							path="/admin/fees"
							element={
								<Main>
									<FeePage />
								</Main>
							}
						/>
						<Route
							exact
							path="/admin/invoices"
							element={
								<Main>
									<InvoicePage />
								</Main>
							}
						/>
						<Route
							exact
							path="/admin/transactions"
							element={
								<Main>
									<TransactionPage />
								</Main>
							}
						/>
						<Route
							exact
							path="/admin/pockets"
							element={
								<Main>
									<PocketPage />
								</Main>
							}
						/>
						<Route
							exact
							path="/admin/articles"
							element={
								<Main>
									<ArticlePage />
								</Main>
							}
						/>
						<Route
							exact
							path="/admin/tables"
							element={
								<Main>
									<Tables />
								</Main>
							}
						/>
						<Route
							exact
							path="/admin/settings"
							element={
								<Main>
									<SettingPage />
								</Main>
							}
						/>
						<Route
							path="/*"
							element={
								context.isLoggedIn ? <Navigate to="/admin/dashboard" /> : <Navigate to="/login" />
							}
						/>
					</>
				)}
				{/* Admin End */}

				{/* Teacher Start */}
				{context.isLoggedIn && context.role === "TEACHER" && (
					<>
						<Route
							exact
							path="/teacher/dashboard"
							element={
								<Main>
									<TeacherDashboardPage />
								</Main>
							}
						/>
						<Route
							exact
							path="/teacher/years"
							element={
								<Main>
									<TeacherYearPage />
								</Main>
							}
						/>
						<Route
							exact
							path="/teacher/classrooms"
							element={
								<Main>
									<TeacherClassroomPage />
								</Main>
							}
						/>
						<Route
							exact
							path="/teacher/lessons"
							element={
								<Main>
									<TeacherLessonPage />
								</Main>
							}
						/>
						<Route
							exact
							path="/teacher/events"
							element={
								<Main>
									<TeacherEventPage />
								</Main>
							}
						/>
						<Route
							path="/*"
							element={
								context.isLoggedIn ? <Navigate to="/teacher/dashboard" /> : <Navigate to="/login" />
							}
						/>
					</>
				)}
				{/* Teacher End */}

				{/* Default */}
				<Route path="/*" element={<Navigate to="/login" />} />
				{/* Default */}
			</Routes>
		</div>
	);
}

export default App;
